<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
            <v-col cols="12">
                <h2>Obras terminadas</h2>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    outlined dense
                    v-model="dateYear"
                    label="Ano"
                    :items="getYears"
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete
                    outlined dense
                    v-model="work_id"
                    label="Obra"
                    :items="getObras"
                    item-value="id"
                    clearable
                    :item-text="item => item.code + ' - ' + item.name"
                />
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn
                    fab
                    class="success"
                    small
                    @click="getWorks"
                >
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <!-- ROLES DTO E CEO -->
                <v-card shaped class="elevation-0" outlined>
                    <v-card-title class="primary white--text">Performance</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" v-if="!loading">
                                <apexcharts type="bar" height="250" :options="chartOptionsPerformance" :series="seriesPerformance"></apexcharts>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <!-- ROLES DTO E CEO -->
                <v-card shaped class="elevation-0" outlined>
                    <v-card-title class="primary white--text">Prazo decorrido vs previsto</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" v-if="!loading">
                                <apexcharts type="bar" height="250" :options="chartOptionsDecorridoPrevisto" :series="seriesDecorridoPrevisto"></apexcharts>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Dashboard from '@/api/Dashboard.js'
import User from '@/api/User.js'
import Work from '@/api/Work.js'
import VueApexCharts from 'vue-apexcharts'

export default{
    name:"ObrasTerminadas",
    props:{
        filter: Object
    },
    components:{
        apexcharts: VueApexCharts,
    },
    data(){
        return {
            work_id: null,
            dateYear: null,
            loading: true,
            commissioners: [],
            engineers: [],
            obras: [],
            data_works_performance: [],
            open_tabs: 0
        }
    },
    mounted(){
        let date = new Date()
        this.dateYear = date.getFullYear();
        this.fillBaseData()
    },
    updated(){
        const labels = document.querySelectorAll('.apexcharts-text.apexcharts-yaxis-label')
                labels.forEach(item => {
                    item.style="cursor: pointer; text-decoration: underline;";

                    item.addEventListener('click', event => {
                        const hash = event.target.innerHTML.toLowerCase()
                        
                        this.data_works_performance.forEach(work => {
                            if(work.code == hash || work.name == hash){
                                if(this.open_tabs == 0){
                                    window.open(process.env.VUE_APP_DOMAIN_FULL + 'admin/works-settings/works/' + work.work_id, '_blank')
                                    this.open_tabs++
                                }else this.open_tabs = 0
                            }    
                        });
                    })
                })
    },
    methods:{
        getWorks(){            
            this.loading = true

            this.filter.finished_date = this.dateYear

            this.filter.work_id = this.work_id
            
            Dashboard.worksFinished(this.filter).then((resp)    =>  {
                this.data_works_performance = resp.data.works_performance
                this.loading = false
            })
        },
        fillBaseData(){
            this.getWorks();

            User.list().then(({data}) => {

                this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));

                this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));
            });

            Work.list().then(({data}) => {
                let data_array = []

                data.forEach(element => {
                    if(element.status == 'FINISHED')
                        data_array.push(element)
                });

                this.obras = data_array;
            });
        }
    },
    computed:{
        getObras(){
            return this.obras
        },
        getYears(){
            let year = 2022
            let i = 0
            let date = new Date()
            let actual = date.getFullYear();

            let arrayYear = []
            for(i = 0; year <= actual; i++){
                arrayYear.push(year)
                year++
            }

            return arrayYear
        },
        chartOptionsPerformance(){
            let categories = []

            // Obter o valor máximo do array
            let maximo = 100

            this.data_works_performance.forEach(element => {
                categories.push(element.code)

                if(element.performance > maximo)
                    maximo = element.performance
            });

            maximo = maximo * 2


            let op = {                
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            distributed: true, // this line is mandatory
                            borderRadius: 4,
                            borderRadiusApplication: 'end',
                            horizontal: true,
                        }
                    },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
                    dataLabels: {
                        enabled: true,
                        formatter: (val, opt) => {
                            if(val == 0)
                                return ''
                            
                                
                            return this.data_works_performance[opt.dataPointIndex].performance + '%'
                        },
                    },
                    legend:{
                        show: false
                    },
                    xaxis: {
                        categories: categories,
                        min: 0,
                        max: maximo,
                        stepSize: 50
                    }
                }
            return op;
        },
        seriesPerformance(){
            let serie = []

            this.data_works_performance.forEach(element => {
                serie.push(element.percentage)
            });

            let s = [{
                        data: serie
                    }]

            return s
        },
        chartOptionsDecorridoPrevisto(){
            let op = {
                    chart: {
                        height: 350,
                        type: 'bar',
                        toolbar: {
                            show: false
                        }
                    },
                    xaxis:{
                        max: 200,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    colors: ['#00E396'],
                    dataLabels: {
                        formatter: (val, opt) => {
                            if(val == '')
                                return 0;

                            let previsto = this.data_works_performance[opt.dataPointIndex].tempo_previsto
                            let decorrido = this.data_works_performance[opt.dataPointIndex].prazo_decorrido
      
                            return decorrido + '/' + previsto.toFixed(0)
                        }
                    },
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        customLegendItems: ['Decorrido', 'Previsto'],
                        markers: {
                            fillColors: ['#00E396', '#775DD0']
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            formatter: (val, opt) => {
                                if(val == 0)
                                return ''

                                
                                return this.data_works_performance[opt.dataPointIndex].prazo_decorrido + ' / ' + this.data_works_performance[opt.dataPointIndex].tempo_previsto.toFixed(0)
                            },
                            title: {
                                formatter: function () {
                                    return 'Decorrido / Previsto: '
                                }
                            }
                        }
                    }
                }
            return op
        },
        seriesDecorridoPrevisto(){
            let data = []
            this.data_works_performance.forEach(element => {
                let y_value = (element.prazo_decorrido * 100) / element.tempo_previsto
              
                if(y_value > 100)
                    y_value = (y_value / 100) + 100

                let greater_value = 100 //y_value > 100 ? y_value - 100 : 100;

                data.push({
                    x:  element.name,
                    y:  y_value.toFixed(2), //value,
                    goals: [
                    {
                        name: 'Decorrido / Previsto: ',
                        value: greater_value.toFixed(2), //element.prazo_decorrido > element.tempo_previsto ? element.prazo_decorrido : element.tempo_previsto,
                        strokeWidth: 2,
                        strokeHeight: 5,
                        strokeDashArray: 2,
                        strokeColor: '#775DD0'
                    }  
                    ]
                })
            });

            let s = [
            {
              name: 'Actual',
              data: data
            }
          ]
          return s
        }
    }
}
</script>