<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-hard-hat</v-icon> <span>Gestão de Obras</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <v-icon color="success" class="ml-4">
        mdi-file-export
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Exportar ficheiro

      <div style="position:relative" class="mb-10">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/works-settings/works/create"
                  v-if="$root.session.hasPermission(['super', 'works.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-row class="mt-4">
          <v-col cols="12" md="7">
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :expanded.sync="expanded"
              :single-expand="true"
              show-expand
              :options.sync="options"
              :class="this.$root.$vuetify.theme.isDark ? 'secondary mt-16' : 'mt-16'"
              locale="pt-pt"
            >

            <template v-slot:item.code="{ item }">
              <span class="text-caption">{{ item.code }}</span>
            </template>
            
            <template v-slot:item.client="{ item }">
              <span class="text-caption">{{item.client != null ? item.client.name : ''}}</span>
            </template>

            <template v-slot:item.name="{ item }">
              <span class="text-caption">{{item.name}}</span>
            </template>

              <template v-slot:item.status="{ item }">
                <div class="text-center">
                <span v-if="status == null">-</span>
                <Tooltip
                  v-else-if="status.find(i => i.id == item.status).name == 'Iniciada'"
                  class="my mr-4"
                  color="success"
                  :text="'Iniciada'"
                  top
                >
                  <span ><v-icon color="success" >mdi-play-circle</v-icon></span>
                </Tooltip>

                <Tooltip
                  v-else-if="status.find(i => i.id == item.status).name == 'A Aguardar'"
                  class="my mr-4"
                  color="warning"
                  :text="'A Aguardar'"
                  top
                >
                  <span><v-icon color="warning">mdi-stop-circle</v-icon></span>
                </Tooltip>

                <Tooltip
                  v-else-if="status.find(i => i.id == item.status).name == 'Terminada'"
                  class="my mr-4"
                  color="error"
                  :text="'Terminada'"
                  top
                >
                  <span><v-icon color="error">mdi-check-circle</v-icon></span>
                </Tooltip>

                <Tooltip
                  v-else-if="status.find(i => i.id == item.status).name == 'Suspensa'"
                  class="my mr-4"
                  color="gray"
                  :text="'Suspensa'"
                  top
                >
                  <span><v-icon color="gray">mdi-alert</v-icon></span>
                </Tooltip>
              </div>
              </template>

              <template v-slot:item.started_at="{ item }">
                <span class="text-caption">{{getTableDate(item.started_at)}}</span>
              </template>

              <!--<template v-slot:item.finished_at="{ item }">
                {{getTableDate(item.finished_at)}}
              </template>-->

              <template v-slot:item.actions="{item}">
                <v-row>
                  <v-col cols="12" md="auto">
                    <IconView
                      small
                      class="mr-2"
                      @click="viewWork(item)"
                    />
                  </v-col>
                  <v-col cols="12" md="auto" v-if="$root.session.hasPermission(['super', 'export.equipments']) && item.status != 'FINISHED'">
                    <IconExport
                      class="mr-2"
                      text="Relatório Equipamentos e Viaturas por Obra"                      
                      @click="workToExport = item; exportingWorkEquipments = true"
                    />
                  </v-col>
                  <v-col cols="12" md="auto" v-if="!item.has_diaries && $root.session.hasPermission(['super', 'works.delete'])">
                    <IconRemove                      
                      class="mr-2"
                      @on-submit="deleteWork(item)"
                    />
                  </v-col>
                </v-row>
              </template>

              <!-- EXPANDABLE ROW -->
              <template v-slot:expanded-item="{ item }">
              
                <!-- EXPORTAR REND MAO OBRA-->
                <td :colspan="headers.length">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-card-subtitle>Exportar Rend. Mão Obra</v-card-subtitle>           
                    </v-col>    
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="menu1"
                        v-model="modalPicker1"
                        :close-on-content-click="false"
                        :return-value.sync="datePicker1"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="datePicker1"
                            label="Data inicial"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datePicker1"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modalPicker1 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(datePicker1)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>   
                    <v-col cols="12" md="3">
                    
                    <v-menu
                      ref="menu"
                      v-model="modalPicker2"
                      :close-on-content-click="false"
                      :return-value.sync="datePicker2"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datePicker2"
                          label="Data final"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datePicker2"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalPicker2 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(datePicker2)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    
                    
                    <Tooltip
                      class="my mr-4"
                      color="success"
                      :text="'Exportar Mão Obra Rendimento'"
                      top
                    >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          class="mt-4 mb-4 md:mb-0"
                          style="float:right"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                        <v-icon
                          right
                          dark
                          class="mr-2"
                        >
                          mdi-download
                        </v-icon>
                        
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(t, index) in exportItems"
                          :key="index"
                          @click="exportMORendimento(item, t.title)"
                        >
                          <v-list-item-title>{{ t.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                        </v-menu>
                      </Tooltip>
                    </v-col>
                  </v-row>
                  </td>
            </template>

            <!-- END EXPANDABLE ROW -->
            </v-data-table>
          </v-col>
          <v-col cols="12" md="5">
            <MapMarkers :markers="locations" :filter="searchFilter"/>
          </v-col>
        </v-row>
        
        <v-row class="text-caption font-weight-bold" justify="end">
          <v-col cols="12" md="auto">
            <v-icon color="success">mdi-play-circle</v-icon> - Iniciada
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="warning">mdi-stop-circle</v-icon> - A Aguardar
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="error">mdi-check-circle</v-icon> - Terminada
          </v-col>
          <v-col cols="12" md="auto">
            <v-icon color="gray">mdi-alert</v-icon> - Suspensa
          </v-col>
        </v-row>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetWorks">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchWorks">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
               <v-select
                  v-model="filter.status"
                  :items="status"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  label="Estado"
                  prepend-inner-icon="mdi-list-status"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="$root.session.hasRole(['dto', 'ceo', 'super', 'administrativo'])">
               <v-select
                  v-model="filter.commissioner_id"
                  :items="commissioners"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Encarregado"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <!--<v-list-item v-if="!$root.session.hasRole(['maquinista'])">
               <v-select
                  v-model="filter.overseer_id"
                  :items="overseers"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Responsável de Equipamento"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>-->

            <v-list-item v-if="$root.session.hasRole(['ceo', 'super', 'administrativo'])">
               <v-select
                  v-model="filter.engineer_id"
                  :items="engineers"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Engenheiro"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="started" @on-ok="started = false" >
        Exportação Iniciada, verifique as notificações para extrair o documento!
      </DialogSuccess>

      <Dialog 
        :opened="exportingWorkEquipments">
        <template #content>
          <v-container fluid>
            <v-card-title class="mt-n8">Escolha o intervalo:</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_started_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Inicio"
                        v-model="equipment.export.started_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.started_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.started_at"
                    locale="pt-pt"
                    @input="equipment_export_started_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="equipment_export_finished_at"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Data Fim"
                        v-model="equipment.export.finished_at"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="equipment.export.finished_at = null"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="equipment.export.finished_at"
                    locale="pt-pt"
                    @input="equipment_export_finished_at = false;"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #footer>
            <v-btn
              color="error"
              depressed
              @click="exportingWorkEquipments = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="exportEquipmentWork()"
            >
              Exportar
            </v-btn>
        </template>
      </Dialog>
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
  </section>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import IconExport from '@/components/ui/IconExport.vue';
import Dialog from '@/components/ui/Dialog.vue';
import Work from "@/api/Work.js";
import User from "@/api/User.js";
import Export from "@/api/Export.js";
import dayjs from 'dayjs';
import Tooltip from '@/components/ui/Tooltip.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import MapMarkers from '@/components/ui/maps/MapMarkers.vue'

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    IconExport,
    Tooltip,
    Dialog,
    ErrorSnackbar,
    MapMarkers
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchWorks()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Obras";

    Work.listStatus().then(({data}) => {
      this.status = Object.keys(data).map(item => {return {id: item, name: data[item]}});
    });

    User.list().then(({data}) => {
      this.commissioners = data.filter(item => item.roles.some(role => role.name == "encarregado"));

      this.overseers = data.filter(item => item.roles.some(role => role.name == "maquinista"));

      this.engineers = data.filter(item => item.roles.some(role => role.name == "dto"));
    });
  },
  data: () => ({
    exportItems:[
      {
        'title': 'PDF'
      },
      {        
        'title': 'Excel'
      }
    ],
    searchFilter: null,
    expanded: [],
    locations: [],
    modalPicker1: false,
    modalPicker2: false,
    datePicker1: null,
    datePicker2: null,
    error: {
      title: '',
      message: '',
      show:false,
    },
    workToExport: null,
    exportingWorkEquipments: false,
    equipment_export_started_at: false,
    equipment_export_finished_at: false,
    equipment: {
      export: {
        started_at: null,
        finished_at: null,
      }
    },
    headers: [
      {
        text: 'Código',
        sortable: true,
        value: 'code'
      },
      {
        text: 'Nome',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Cliente',
        sortable: false,
        value: 'client',
      },
      {
        text: 'Estado',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Inicio',
        sortable: true,
        value: 'started_at',
      },
      /*{
        text: 'Fim',
        sortable: true,
        value: 'finished_at',
      },*/
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
      commissioner_id:null,
      overseer_id:null,
      engineer_id:null,
      status: null,
    },
    status: [],
    commissioners:[],
    overseers:[],
    engineers:[],
    started:false,
    finished:false,
    exporting: false,
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "works.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    exportMORendimento(i, type){
      let data = {
        type: type,
        data1: this.datePicker1,
        data2: this.datePicker2
      }
      if(this.datePicker1 == null || this.datePicker2 == null){
        this.error.message = "Por favor selecione um intervalo de datas"
        this.error.show = true
        return
      }
      Export.exportMORendimentos(i.id, data)
        .then((resp) => {
          if(resp.data.success){
              if(resp.data.object == null){
                  this.error.message = "Não há dados para mostrar"
                  this.error.show = true
                  this.exportingDocument = false
                  return                                
              }else{
                  window.open(resp.data.object.url, '_blank')
                  Export.delete(resp.data.object.path)
                  .then(() => {
                      
                  });
              }
          }     
        });
      return ''
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["works-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["works-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }

      Work.getLocations().then(({data}) => {
        this.locations = data
      });
    },
    searchWorks(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      this.searchFilter = filter

      console.log(filter)

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["works-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Work.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });


        this.loading = false;

      });
    },
    resetWorks()
    {
      this.filter = {
        is_active: 1
      };

      this.searchWorks();
    },
    viewWork(item){
      this.$router.push('/admin/works-settings/works/' + item.id);
    },
    deleteWork(item){
      
      Work.delete(item.id)
        .then(() => {
          this.searchWorks();
        });
    },
    async exportEquipmentWork() {
        await Work.exportEquipments(this.workToExport.id, this.equipment.export)
            .then((resp) => {
            this.exportingWorkEquipments = false;

            this.exportingDocument = false

            if(resp.data.success){
                
                if(resp.data.object == null)
                {
                    this.error.message = "Não há dados para mostrar"
                    this.error.show = true
                    this.exportingDocument = false
                    return
                }else 
                    window.open(resp.data.object)

            }
        });
    }
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Obras',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
