<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-hard-hat</v-icon> <span>Gestão de Partes Diárias</span>
      </h1>
      <v-icon color="warning">
        mdi-marker-check
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Marcar como lida

      <v-icon color="primary" class="ml-4">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="success" class="ml-4">
        mdi-check
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Aprovar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <v-icon color="orange" class="ml-4">
        mdi-cloud-off-outline
      </v-icon>
      Criada offline

      <v-switch
        v-model="filter.diaries_offline"
        label="Ver diárias offline"
        color="success"
      />

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="modalChooseData = true"
                >
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </template>
              <span>Exportar</span>
            </v-tooltip>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/diaries/create"
                  v-if="$root.session.hasPermission(['super', 'diaries.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          v-show="!filter.diaries_offline"
          :item-class= "row_classes"   
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.read="{item}">
            <v-row>
              <v-col cols="12" md="auto" v-if="item.readed == false">
                <Tooltip
                  class="my-4 mr-4"
                  color="warning"
                  text="Marcar como lida"
                  top                
                >
                  <v-icon color="warning" @click="markDiaryReaded(item)">
                    mdi-marker-check
                  </v-icon>
                </Tooltip> 
              </v-col>
              <v-col cols="12" md="auto" v-if="item.rejected == true">
                <Tooltip
                  class="my-4 mr-4"
                  color="red"
                  text="Diária rejeitada (Clique para saber mais)"
                  top                
                >
                  <v-icon color="red" @click="showRejectedHistory(item)">
                    mdi-hospital-box
                  </v-icon>
                </Tooltip>
              </v-col>
              <v-col cols="12" md="auto" v-if="item.created_offline">
                <Tooltip
                  class="my-4 mr-4"
                  color="orange"
                  text="Diária criada offline"
                  top                
                >
                  <v-icon color="orange">
                    mdi-cloud-off-outline
                  </v-icon>
                </Tooltip>
              </v-col>
            </v-row>

            

            
            
          </template>
        
          <template v-slot:item.work="{ item }">
            {{ item.work_object.code }} : {{ item.work_object.name }}
          </template>

          <template v-slot:item.work_front="{ item }">
            {{ item.work_front == null ? '' : item.work_front.reference }}
          </template>

          <template v-slot:item.status="{ item }">
              <v-img style="margin: auto; display:block;" v-if="item.status == 'FINISHED_APPROVAL'" src="@/assets/app/certinho.png" max-width="20px"></v-img>
              <v-img style="margin: auto; display:block;" v-else src="@/assets/app/certinho_unchecked.png" max-width="20px"></v-img>
          </template>

          <template v-slot:item.date="{ item }">
            {{getTableDate(item.date)}}
          </template>
          

          <template v-slot:item.actions="{item}">
            <v-row no-gutters>
              <v-col cols="12" md="auto">
                <Tooltip
                  color="primary"
                  text="Visualizar"
                  top
                >
                  <v-btn class="mt-2" small icon color="primary" @click="viewDiary(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </Tooltip>
              </v-col>
              <v-col cols="12" md="auto" class="">
                <Tooltip
                  color="success"
                  text="Pré-visualização de diária"
                  top
                >
                  <v-btn class="mt-2" small icon color="success" @click="previewDiary.id = item.id; previewDiary.show=true;">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </Tooltip>
              </v-col>
              <v-col cols="12" md="auto" v-if="false">
                <v-btn icon class="mt-2" small color="success" v-for="approval of getPossibleApprovals(item)" v-bind:key="approval" @click="confirmApprove(item, approval)">
                  <v-icon                   
                    color="success"                    
                  >
                    mdi-check
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" md="auto" v-if="item.files.length > 0">
                <IconDownload
                  :text="'Download de Imagens'"                  
                  @click="downloadImages(item)"
                />
              </v-col>
              <v-col class="mt-2" cols="12" md="auto" v-if="$root.session.hasPermission(['super', 'diaries.delete']) && item.status != 'FINISHED_APPROVAL'">
                <IconRemove                  
                  @on-submit="deleteDiary(item)"
                />
              </v-col>
            </V-row>
          </template>
          <!-- EXPANDABLE ROW -->
          <template v-slot:expanded-item="{ item }">
            
              <td colspan="4">
                <v-row>
                  <v-card
                    elevation="10"
                    color="#009688"
                    dark
                    :width="width"
                    class="mt-6 mb-4 ml-4"
                    height="200"
                  >
                  <v-row>
                    <v-card-title>
                      <v-icon
                        large
                        left
                      >
                        mdi-timer
                      </v-icon>
                      <span class="text-h6 font-weight-light">Criação</span>
                    </v-card-title>
                  </v-row>

                    <v-card-text class="text-caption font-weight-bold">
                      Criada a: <p class="font-weight-light">{{getDateHour(item.created_at)}}h</p>
                    </v-card-text>
                    

                    <v-card-actions class="mt-n1">
                      <v-list-item class="grow">     
                        <v-list-item-avatar color="grey darken-3" >
                          <v-icon >
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                  
                        <v-list-item-content>
                          <v-list-item-title  style="float:right;">{{item.user_created != null ? item.user_created.name : '-'}}</v-list-item-title>
                        </v-list-item-content>
                        
                      
                      </v-list-item>
                    </v-card-actions>
                  </v-card>
                  <v-card
                    elevation="10"
                    color="warning"
                    dark
                    :width="width"
                    class="mt-6 mb-4 ml-4"
                    height="200"
                  >
                  <v-row>
                    <v-card-title>
                      <v-icon
                        large
                        left
                      >
                        mdi-pencil
                      </v-icon>
                      <span class="text-h6 font-weight-light">Atualizações</span>
                    </v-card-title>
                  </v-row>
                    <v-card-text class="text-caption font-weight-bold mt-n6">
                      Atualizada a: <p class="font-weight-light">{{getDateHour(item.updated_at)}}h</p>
                    </v-card-text>
                    <v-card-text class="text-caption font-weight-bold mt-n10">
                      Nº atualizações: <p class="font-weight-light">{{item.editions_qty}}</p>
                    </v-card-text>

                    <v-card-actions :class="width > 150 ? 'mt-n10' : 'mt-n16 ml-6'">
                      <v-list-item class="grow">     
                        <v-list-item-avatar color="grey darken-3" >
                          <v-icon >
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                  
                        <v-list-item-content>
                          <v-list-item-title  style="float:right;">{{item.user_edited != null ? item.user_edited.name : '-'}}</v-list-item-title>
                        </v-list-item-content>
                        
                      
                      </v-list-item>
                    </v-card-actions>
                  </v-card>
                  <v-card
                    elevation="10"
                    color="secondary"
                    class="mt-6 mb-4 ml-4"
                    dark
                    :width="width"
                    height="200"
                  >
                  <v-row>
                    <v-card-title>
                      <v-icon
                        large
                        left
                      >
                        mdi-check-outline
                      </v-icon>
                      <span class="text-h6 font-weight-light">Aprovações</span>
                    </v-card-title>
                  </v-row>
                    <v-card-text class="text-caption font-weight-bold mt-n6">
                      Estado: <p class="font-weight-light">{{getStatus(item)}}</p>
                    </v-card-text>
                    <!--<v-card-text v-if="item.approvals.dto.approved_at != null" class="text-caption font-weight-bold mt-n10">
                      DTO: <p class="font-weight-light">{{item.approvals.dto.approved_at}}</p>
                    </v-card-text>-->
                    <v-card-actions :class="width > 150 ? 'mt-n10' : 'mt-n16 ml-6'">
                      <v-list-item class="grow" v-if="item.approval_history != null">     
                        <v-list-item-avatar color="grey darken-3" >
                          <v-icon >
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                  
                        <v-list-item-content>
                          <v-list-item-title  style="float:right;">{{ item.approval_history == null ? '-' : item.approval_history.name }}</v-list-item-title>
                        </v-list-item-content>
                        
                      
                      </v-list-item>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </td>
              <td>
                <v-menu offset-y v-if="checkIfApproved(item)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon
                      right
                      dark
                      class="mr-2"
                    >
                      mdi-download
                    </v-icon>
                      Exportar
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(t, index) in exportItems"
                      :key="index"
                      @click="exportDiary(item, t.title)"
                    >
                      <v-list-item-title>{{ t.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
                
          </template>

          <!-- END EXPANDABLE ROW -->
        </v-data-table>

        <v-simple-table v-if="filter.diaries_offline">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Código
                </th>
                <th class="text-center">
                  Data
                </th>
                <th class="text-center">
                  Obra
                </th>
                <th class="text-center">
                  Frente
                </th>
                <th class="text-center">
                  Opções
                </th>
              </tr>
            </thead>
            <tbody v-for="diary in offlineDiaries" :key="diary.id">
              <tr>
                <td class="text-left">
                  {{diary.code}}
                </td>
                <td class="text-center">
                  {{diary.date}}
                </td>
                <td class="text-center">
                  {{getWorkName(diary.work_id)}}
                </td>
                <td class="text-center">
                  {{diary.work_front_name}}
                </td>
                <td class="text-center">
                  <Tooltip
                    color="primary"
                    text="Visualizar"
                    top
                  >
                    <v-btn class="mt-2" small icon color="primary" @click="viewDiaryOffline(diary)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetDiaries">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchDiaries">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                label="Código diária"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
               <v-autocomplete
                  v-model="filter.work_id"
                  :items="works"
                  :item-text="item => 'Nome: ' + item.name + ' - Código: '+ item.code"
                  item-value="id"
                  menu-props="auto"
                  label="Obras"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-autocomplete>
            </v-list-item>

            <v-list-item>
               <v-select
                  v-model="filter.status"
                  :items="status"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  label="Estado"
                  prepend-inner-icon="mdi-list-status"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['encarregado'])">
               <v-select
                  v-model="filter.commissioner_id"
                  :items="commissioners"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Encarregado"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['maquinista'])">
               <v-select
                  v-model="filter.overseer_id"
                  :items="overseers"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Responsável de Equipamento"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                ></v-select>
            </v-list-item>

            <v-list-item v-if="!$root.session.hasRole(['dto'])">
               <v-select
                  v-model="filter.engineer_id"
                  :items="engineers"
                  :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                  item-value="id"
                  menu-props="auto"
                  label="Engenheiro"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                ></v-select>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <DialogSuccess :opened="approved" @on-ok="approved = false" >
        Diário aprovado com sucesso!
      </DialogSuccess>
      <v-dialog
            v-model="modalChooseData"
            persistent            
            width="350px"
        >
        <ChooseData 
          @close="modalChooseData = false"
          @confirm="confirmExport"
          :secondOption="works"
          secondOptionLabel="Obra"/>
      </v-dialog>
      
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    <DialogConfirmation v-if="checkApproval.showModal"    
      title="Confirme a ação" 
      type="info" 
      :opened="checkApproval.showModal"
      @on-submit="approve" 
      @on-cancel="checkApproval.showModal = false" >
      Tem a certeza que deseja aprovar?
    </DialogConfirmation>
    <RejectionHistory v-if="showRejected.show" :item="showRejected.item" @on-cancel="showRejected.show = false" :opened="showRejected.show" vid="rejected_history" title="Histórico de rejeições"/>
    <v-dialog
      v-model="previewDiary.show"
      max-width="900px">
      <PreviewDiary v-if="previewDiary.show" @close="previewDiary.show=false" :id="previewDiary.id"/>
    </v-dialog>  
  </section>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import IconDownload from '@/components/ui/IconDownload.vue';
import Work from "@/api/Work.js";
import User from "@/api/User.js";
import Diary from "@/api/Diary.js";
import Export from "@/api/Export.js";
import dayjs from 'dayjs';
import Downloads from "@/api/Downloads.js";
import Tooltip from '@/components/ui/Tooltip.vue';
import ChooseData from '@/components/general/dialogs/ChooseDate.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import RejectionHistory from '@/components/diaries/dialogs/RejectionHistory'

import PreviewDiary from '@/components/diaries/PreviewDiary'
import { getDiariesOffline } from '../../idb'




export default {
  components: {
    IconRemove,
    DialogSuccess,
    IconDownload,
    Tooltip,
    ChooseData,
    ErrorSnackbar,
    DialogConfirmation,
    RejectionHistory,

    PreviewDiary
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        if(this.$root.session.hasRole(['administrativo'])){
          if(this.changedOneTime == false){
            this.filter.status = 'FINISHED_APPROVAL'
            this.changedOneTime = true
          }
        }
        setTimeout(() => {this.searchDiaries()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Partes Diárias";
  },
  data: () => ({
    previewDiary: {
      show: false,
      id: null
    },
    modalChooseData: false,
    changedOneTime: false,
    exportItems:[
      {
        'title': 'PDF'
      },
      {        
        'title': 'Excel'
      }
    ],
    expanded: [],
    headers: [
      {
        text: '',
        sortable: true,
        value: 'read',
      },
      {
        text: 'Código',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Obra',
        sortable: true,
        value: 'work',
      },
      {
        text: 'Frente',
        sortable: false,
        value: 'work_front'
      },
      {
        text: 'Aprovação',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Data',
        sortable: true,
        value: 'date',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
      { text: '', value: 'data-table-expand' },
    ],
    items: [],
    total: 0,
    loading: false,
    error: {
      title: '',
      message: '',
      show:false,
    },
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      work_id: null,
      commissioner_id:null,
      overseer_id:null,
      engineer_id:null,
      status: null,
      diaries_offline: false
    },
    status: [],
    works: [],
    commissioners:[],
    overseers:[],
    engineers:[],
    approved:false,
    checkApproval:{
      showModal: false,
      item: null,
      aprroval: null
    },
    showRejected:{
      show: false,
      item: null
    },
    offlineDiaries: [],
    standalone: false
  }),
  async mounted(){
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log("This is running as standalone.");
      this.standalone = true
    }

    if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();

    this.offlineDiaries = await getDiariesOffline();

    console.log("DIARIAS OFFLINE")
    console.log(this.offlineDiaries)

    console.log("DIARIAS EM CACHE")
    console.log(this.items)

    if(this.$route.params.code != undefined){
      this.filter.code = this.$route.params.code
      //this.searchDiaries()
    }else{
      this.filter.code = null
      //this.searchDiaries()
    }

    await Diary.listStatus().then(({data}) => {
      this.status = Object.keys(data).map(item => {return {id: item, name: data[item]}});
    });

    Work.list().then(({data}) => {
      console.log("WORKS")
      this.works = data;
      console.log(this.works)
    });

    User.list().then(({data}) => {

      this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));

      this.overseers = data.filter(item => item.roles?.some(role => role.name == "maquinista"));

      this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));
    });
  },
  methods:{
    getWorkName(id){
      let aux = null
      this.works.forEach(element => {
        if(element.id == id)
          aux = element
      });

      return aux == null ? 'NaN' : aux.name
    },
    getStatus(item){
      let f = this.status.find(i => i.id == item.status)
      return f == null ? '' : f.name
    },
    showRejectedHistory(item){
      this.showRejected.item = item
      this.showRejected.show = true
    },
    async confirmExport(d1, d2, type, work){
      this.exportingDocument = true
      if(work == null){
        this.error.title = "Falta de dados"
            this.error.message = "Não preencheu os campos todos"
            this.error.show = true
            return
      }
      let data ={
        dataInicial: d1,
        dataFinal: d2,
        type: type,
        work: work
      }

      await Export.exportDiaries(data)
          .then((resp) => {
            if(resp.data.url == null){
              this.error.message = "Sem Partes Diárias para apresentar"
              this.error.show = true
              this.exportingDocument = false
              return;
            }
            window.open(resp.data.url, '_blank')
            Export.delete( resp.data.path)
              .then(() => {
                this.exportingDocument = false
              });
          });

    },
    checkIfApproved(i){
      if(i.status == "FINISHED_APPROVAL")
        return true
        
      return false
    },
    exportDiary(i, type){
      let data = {
        type: type
      }
      Export.exportDiary(i.id, data)
          .then((resp) => {
            window.open(resp.data.url, '_blank')
            Export.delete(resp.data.path)
              .then(() => {
                
              });
          });
    },
    row_classes(i){
      if(i.readed == true)
        return '';
    },
    async markDiaryReaded(i){
      await Diary.markRead(i.id, this.$root.session.id)
          .then(() => {
              i.readed = true
            });
    },
    async downloadImages(i){
      await Downloads.get(i.id)
        .then((response) => {
          window.open(response.data, '_blank');
        });

      await Downloads.delete(i.id)
        .then(() => {
          
        });


        //\Route::name("admin.download.delete")->get("images/{id}". "Delete");
    },
    getDateHour(d){
      let date = new Date(d)

      var formatOptions = { 
        day:    '2-digit', 
        month:  '2-digit', 
        year:   'numeric',
        hour:   '2-digit', 
        minute: '2-digit',
        hour12: false 
      };

      return date.toLocaleDateString('pt-PT', formatOptions);
    },
    lastUpdateString(i, v){
      if(i.user_edited == null){
        return "-"
      }
      if(v == 1)
        return i.user_edited.name + ' em ' + i.updated_at.split('T')[0] 
      else return i.updated_at.split('T')[0] 
    },
    getPossibleApprovals(item){
      
      let approvals = [];

      for(let approval in item.approvals){

        let auxApproval = item.approvals[approval];

        if(this.$root.session.hasRole(["super", approval]) && auxApproval.approved_at == null){

          approvals.push(approval);
        }
      }

      return approvals;
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["diaries-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["diaries-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchDiaries(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["diaries-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Diary.search(request).then(response => {
        
        let data = response.data;
        
        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetDiaries()
    {
      this.filter = {
        code:null,
        work_id: null,
        commissioner_id:null,
        overseer_id:null,
        engineer_id:null,
        status: null,
      }
      this.searchDiaries();
    },
    viewDiary(item){
      if(this.standalone)
        this.$router.push('/admin/diaries/' + item.id)
      else window.open(process.env.VUE_APP_DOMAIN_FULL + 'admin/diaries/' + item.id, '_blank')
    },
    viewDiaryOffline(item){
      this.$router.push('/admin/diaries-offline/' + item.id)
    },
    deleteDiary(item){
      Diary.delete(item.id)
        .then(() => {
          this.searchDiaries();
        });
    },
    confirmApprove(item, approval){
      this.checkApproval.item = item
      this.checkApproval.approval = approval

      this.checkApproval.showModal = true
    },
    async approve(){
      let item = this.checkApproval.item
      let approval = this.checkApproval.approval
      let parent = this
      await Diary.approve(item.id, approval)
        .then(() => {
          this.searchDiaries();
          parent.checkApproval.showModal = false

          this.approved = true;

        });
    },
    getWorkKey(id, key){
      let work = this.works.find(i => i.id == id);

      return work == undefined ? "---" : work[key];
    }
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Diários',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    },
    width () {
        if(window.innerWidth <= 1366){
          return 150
        }
        return 250
      }
  }
};
</script>