<template>
    <section class="mt-16">
        <h1 class="primary--text">
            <v-icon color="primary">mdi-view-dashboard</v-icon> <span>Dashboard</span>
        </h1>
        <v-row>
            <v-col cols="12" md="6" v-if="$root.session.hasPermission(['super', 'works.read', 'works.create'])">
                <Card class="mt-4 mb-4">
                <v-card-title>Obras</v-card-title>
                <v-card-text>
                    <v-row>
                    <v-col cols="12" md="6" v-if="$root.session.hasPermission(['super', 'works.read'])">
                        <v-btn
                        depressed
                        color="primary"
                        class="mr-4"
                        type="button"
                        large
                        block
                        link
                        to="/admin/works-settings/works"
                        >
                        Ver Obras
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" v-if="$root.session.hasPermission(['super', 'works.create'])">
                        <v-btn
                        depressed
                        color="primary"
                        class="mr-4"
                        type="button"
                        large
                        block
                        link
                        to="/admin/works-settings/works/create"
                        >
                        Registar Obras
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-card-text>
                </Card>
            </v-col>
            <v-col  cols="12" md="6" v-if="$root.session.hasPermission(['super', 'diaries.read', 'diaries.create'])">
                <Card class="mt-4 mb-4">
                <v-card-title>Partes Diárias</v-card-title>
                <v-card-text>
                    <v-row>
                    <v-col  cols="12" md="6" v-if="$root.session.hasPermission(['super', 'diaries.read'])">
                        <v-btn
                        depressed
                        color="primary"
                        class="mr-4"
                        type="button"
                        large
                        block
                        link
                        to="/admin/diaries"
                        >
                        Ver Partes Diárias
                        </v-btn>
                    </v-col>
                    <v-col  cols="12" md="6" v-if="$root.session.hasPermission(['super', 'diaries.create'])">
                        <v-btn
                        depressed
                        color="primary"
                        class="mr-4"
                        type="button"
                        large
                        block
                        link
                        to="/admin/diaries/create"
                        >
                        Registar Partes Diárias
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-card-text>
                </Card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <WorkerView v-if="false" />
                <GeneralView />
            </v-col>
        </v-row>
    </section>
</template>
<script>
import Card from '@/components/ui/Card.vue';
import User from '@/api/User.js'
import GeneralView from '@/views/dashboardV2/GeneralView.vue'
import WorkerView from '@/views/dashboardV2/WorkerView.vue'

export default{
    name: "DashboardV2",
    components:{
        Card,
        GeneralView,
        WorkerView
    },
    data(){
        return{
            tab: 0,
            loading: true,
            engineers:[],
            commissioners:[],
        }
    },
    mounted(){
        if(localStorage["dashboard-v2-" + this.$root.session.id] != undefined){
    
            let data = JSON.parse(localStorage["dashboard-v2-" + this.$root.session.id]);

            this.tab = data.tab

        }

        this.fillBaseData()
    },
    methods:{
        async fillBaseData(){
            await User.list().then(({data}) => {

                this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));

                this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));

            });

            this.loading = false
        },
        onChangeTab(){
            localStorage["dashboard-v2-" + this.$root.session.id] = JSON.stringify({
                tab: this.tab
            });
        }
    }
}
</script>